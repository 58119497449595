.content-wrapper {
  width: min(90%, 1400px);
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--pl-layout-background);
}

footer {
  margin-top: auto;
}

.staticText {
  max-width: 650px;
  margin: auto;
  text-align: left;
}

.staticText h1,
.staticText strong {
  color: var(--pl-primary);
}