@import "../../index";

.login-form {
  display: flex;
  flex-direction: column;
  // max-width: fit-content;
  text-align: left;
  margin-bottom: 1rem
}

.text{
  font-family: $fontPrimary;
}

p a{
  color: $primary;
  font-family: $fontPrimary;
  text-decoration: underline;
}


.title {
  color: $primary;
  font-family: $fontPrimary;
  font-size: 2.2rem;
  text-align: start;
}

.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img-container {
  width: 100%;
  max-height: 100%;
}

.main-container-login {
  width: 100vw;
  margin: auto;
  font-family: $fontPrimary !important;

}

.img-pharmacy {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.login-form-input {
  @extend .grey-form-input;
  margin-bottom: 8px;
}

.login-form-label {
  @extend .grey-form-label;
}

.logo-login {
  height: 300px;
  width: 495px;
  object-fit: cover;
}

.form-floating {
  margin-bottom: 1px;
}
