@import "../../index";

@import "../LoginView/style";

.progress-bar-step {
    width: 8vw;
    height: 6px;
    background-color: $greyBgColor;
    margin: 0 4px;
}

.progress-bar-step-active {
    background-color: $primary;
}

.text {
    font-family: $fontPrimary;
}