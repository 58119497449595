.product-price {
    color: #5ecc78;
}

.product-description {
    font-size: 1.5rem;
    color: #777;
    text-align: start;
}

.product-see-more {
    color: #5ecc78;
    font-size: 1rem;
}

.product-prescription {
    font-style: italic;
}
