@import "../../index";

.item_card .input-group>.form-control {
  flex: 0;
  min-width: 40px;
}

.hover-text {
  position: relative;
  border-bottom: 1px dotted black;
}

.hover-text:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 110px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 4px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hover-text:hover:before {
  opacity: 1;
  visibility: visible;
}

.card-product-title {
  min-height: 7vh;
  cursor: pointer;
}

.product-hidden {
  background-color: #e6e6e6;
}
