@use "../node_modules/bootstrap/scss/bootstrap";

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '../node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
$primary: #5ecc78;
$secondary: #09b2e7;

$greyBgColor: #EFEEF6;
$body-color: #555;
$headings-color: #333;
$headings-font-weight: 700;
$fontPrimary: "Dosis", sans-serif;

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: #fff !important;
}

.grey-bg-color {
  background-color: $greyBgColor;
}

.pointer {
  cursor: pointer;
}

.page-title {
  font-family: Dosis, serif;
  @extend .text-start;
  @extend .text-primary;
  @extend .mt-3;
  color: $primary;
  font-size: 2.5rem;
  font-weight: bold;
}

.page-subtitle {
  font-family: Dosis, serif;
  @extend .text-start;
  @extend .mt-3;
  color: $secondary !important;
  font-size: 2rem;
  font-weight: 600;
}

.grey-form-input {
  border: 0 !important;
  background-color: $greyBgColor !important;
  @extend .rounded;
}

.grey-form-label {
  font-family: $fontPrimary !important;
}


/* Dev utils */
.dev-border-red {
  border: 1px solid red;
}

.dev-border-blue {
  border: 1px solid blue;
}



// 3. Include remainder of required Bootstrap stylesheets
@import '../node_modules/bootstrap/scss/variables';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/utilities';

// 6. Include any optional Bootstrap components as you like
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/pagination';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/carousel';
@import '../node_modules/bootstrap/scss/spinners';
@import '../node_modules/bootstrap/scss/offcanvas';
// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '../node_modules/bootstrap/scss/utilities/api';
