@keyframes slide-up {

  0% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0);
  }
}

.fd-name {
  font-weight: bold;
  color: white !important;
  transform: translateY(0);
}

.fd-description {
  color: white !important;
  display: none;
  /* transform: translateY(100%); */
}

.fd-card {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  min-height: 200px;
  background-size: cover;
  background-blend-mode: overlay;
  overflow: hidden;

  border-radius: 0.5em;
  padding: 1em;
  transition: all 150ms ease-out;
}

.fd-card:hover .fd-text-wrapper {
  animation: slide-up 0.3s cubic-bezier(0.65, 0, 0.35, 1) both;
}

.fd-card:hover .fd-description {
  display: block;
}