@use "../../../node_modules/bootstrap/scss/bootstrap";

@import "../../index";


.main-container {
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-between
}

.products-container {
  width: 70%;
  text-align: start;
}

.vertical-line {
  border-left: 1px solid black;
  max-width: 1px;
}


/* Product Tab */

.tab-title {
  color: $primary;
  font-size: 1.6rem;
}

.product-price {
  color: $primary;
  font-size: 1.3rem;
}

.product-card-title {
  height: 60px;
}

.product-card-body {
  text-align: start;
}

.spacer {
  width: 40vw;
}

.border-dev {
  border: 0.5px solid #444444 !important;
}

/* Checkout Tab */

.checkout-container {
  margin-right: 9vw;
  width:100%;
}

.date-picker {
  @extend .rounded;
  background-color: #EEE;
  padding: 7px;
}

.react-date-picker__wrapper {
  border: 0 !important;
}

.total-price {
  font-size: 1.6rem;
}

.order-button {
  color: white;
  width: 100%;
}

.form-check-input:focus, .form-check-input:active  {
  outline: none !important;
  box-shadow: none;
}