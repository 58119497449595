@import "../../index";

.business-cond-detail-container {
  @extend .rounded;
  background-color: $greyBgColor;
  width: auto;
  margin: 10px 0;
}


.business-cond-detail-body {
  display: flex;
  width: 100%;
  justify-content: space-around;
}