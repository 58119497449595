@import "../../index";

.section-title {
  color: $primary;
  font-size: 1.5rem;
}


.pharmacy-row {
  @extend .rounded;
  background-color: $greyBgColor;
  padding: 0.7rem;
  font-family: $fontPrimary;
  font-size: 1rem;
  color: black;

}

.product-card-business-condition {
  @extend .rounded;
  background-color: $greyBgColor;
  padding: 0.7rem;
  font-family: $fontPrimary;
  font-size: 1rem;
  color: black;
  display: flex;
  width: fit-content;
}

.product-img-business-condition {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.selected-product-container {
  width: 40vw;
  max-width: 40vw;
}

.product-badges-container {
  width: 30vw;
  max-width: 30vw;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-start;
  max-height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
}


.selected-title {
  font-size: 1.3rem;
  font-family: $fontPrimary;
}

.selected-price {
  font-size: 1rem;
  font-family: $fontPrimary;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.discount-type-selector {
  @extend .rounded;
  @extend .grey-bg-color;
  height: fit-content;
  width: fit-content;
  padding: 8px 3px;
  font-size: 1rem;
}

.type-selected {
  background: $primary;
  //-webkit-border-radius: 80px / 100px;
  //-moz-border-radius:    80px / 100px;
  //border-radius:         80px / 100px;
  @extend .text-white;
  @extend .rounded;
}

.discount-step-form {
  width: 80px;
}

.horizontal-divider-3 {
  width: 100%;
  height: 3px;
  background-color: $greyBgColor;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.horizontal-divider-1 {
  width: 100%;
  height: 1px;
  background-color: $greyBgColor;
}

.form-floating > label {
  padding: 0.4rem 0.2rem;
}


.form-floating > .form-control {
  height: calc(2.5rem + 2px);
}