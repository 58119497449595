@import "../../index";

.main-container-orders {
  width: 100%;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.orders-container {
  width: 100%;

}


/* OrderDetail */

.order-detail-container {
  @extend .rounded;
  background-color: $greyBgColor;
  width: auto;
}

.order-detail-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;
  font-family: $fontPrimary;
  font-size: 1.5rem;
  color: black;
}

.order-detail-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.7rem;
  font-family: $fontPrimary;
}

/* OrderDetailProduct */

$containerWidth: 100px;

.order-detail-product-container {
  $marginHorizontal: 4px;
  margin-left: $marginHorizontal;
  margin-right: $marginHorizontal;
  width: $containerWidth;
  font-family: $fontPrimary;
  font-size: 1rem;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.order-detail-product-img {
  width: $containerWidth;
  height: 100px;
  object-fit: cover;
  @extend .rounded;
}