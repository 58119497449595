@tailwind base;
@tailwind components;
@tailwind utilities;

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)

:root {
  font-family: 'dosis', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Dosis;
    src: url("https://fonts.googleapis.com/css?family=Dosis:400,700&display=swap");
}

.annotationLayer {
  height: 0 !important;
}

input,
textarea,
button {
  font-family: inherit
}

body {
  margin: 0;
  color: var(--pl-body-color);
  display: flex;
  flex-direction: column;
  height: 100vh;
  --bs-body-text-align: center
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Dosis', serif;
}

p {
  font-family: 'Dosis', serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
}

.btn-cta {
  background-color: var(--bs-primary);
  color: white;
  border: none;
  border-radius: 0.5em;
  padding: 0.5rem 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.btn-cta:hover {
  filter: brightness(90%);
  color: white;
}

/* Safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
}

.spinner-page {
  position:fixed;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100vh;
  height: 100vh;
  background:rgba(255,255,255,0.5);
}
