@import "../../index.scss";

.validate-button {
  font-size: 1rem;
}

.text{
  border-radius: 4px;
  background-color: $greyBgColor;
  text-align: center;
  font-size: 1.5rem;
  font-family: $fontPrimary;
  padding: 0.5rem 0rem 0.5rem 0rem;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray color */
  z-index: 999; /* Ensure the overlay is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index:1000;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 70%;
  max-width: 700px; /* Adjust max-width as needed */
  height: 70%;
  max-height: 500px; /* Adjust max-height as needed */
  overflow: auto; /* Enable scrolling if content exceeds popup dimensions */
}

.popup-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-inner input, textarea {
  border: 2px solid lightgreen;
  width: 75%;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

/* Custom select dropdown styles */
.select-wrapper {
  position: relative;
  padding: 10px;
}

.select-wrapper select {
  appearance: none; /* Remove default arrow */
  background-color: #fff;
  width: 100%;
  border: 2px solid lightgreen;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

/* Custom arrow icon */
.select-wrapper::after {
  content: '\25BC'; /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 12px;
  padding: 8px 16px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure clicks go through to select */
}

/* Hover and focus styles */
.select-wrapper select:hover,
.select-wrapper select:focus {
  border-color: #aaa;
}

/* Hover style for arrow icon */
.select-wrapper:hover::after {
  color: #aaa;
}



#checkboxContainer{
  border: 2px solid lightgreen;
  width: 75%;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

#productAddBtn{
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: green;
  color: white;
  width : 25%;
  margin : 10px
}


.popup-inner button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.popup-inner button.validate {
  background-color: green;
  color: white;
  width : 75%;
  margin : 10px
}

.popup-inner button.cancel {
  background-color: red;
  color: white;
  width : 75%;
  margin : 10px
}
