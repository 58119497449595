footer {
  background-color: #333;
  color: white;
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  padding: 1rem 0;
}

footer>div {
  width: min(90%, 1400px);
  margin: auto;
}

.footer-links {
  color: white !important;
}

a {
  text-decoration: none;
}

footer>div>a:not(:first-child) {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-left: 1px solid white;
}
