@keyframes activeTick {
  from {
    width: 0;
  }

  to {
    width: 90%;
  }
}

.pl-header-menu .pl-header-link {
  position: relative
}


.pl-header-menu .pl-header-link:not(.active):hover {
  color: var(--bs-primary) !important;
}

.pl-header-menu a.active {
  font-weight: bold;
}

.pl-header-menu a.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  border-radius: 10rem;
  background-color: var(--bs-primary);
  transform: translate(-50%, 0);
  height: 4px;
  width: 90%;

  animation-name: activeTick;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}

.navbar-nav .pl-header-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.pl-header-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
}

.MuiBadge-badge {
  height: 20px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}




/* Taken from Material UI */
/* https://material-ui.com/components/badge/ */
/* I just didn't want to use the whole library */

.MuiBadge-colorError {
  color: #fff;
  background-color: #f44336;
}

.MuiBadge-anchorOriginTopRightRectangle {
  top: 0;
  right: 0;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0;
}

.MuiBadge-root {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
}